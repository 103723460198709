import { template as template_002e05144e1744d092f7b1c779119f6c } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_002e05144e1744d092f7b1c779119f6c(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
