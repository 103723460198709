import { template as template_aa155d515cfe4c6f9516784be2ab1929 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_aa155d515cfe4c6f9516784be2ab1929(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
