import { template as template_70beda0a854d4b7f9d63e0f082ef624a } from "@ember/template-compiler";
const FKText = template_70beda0a854d4b7f9d63e0f082ef624a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
