import { template as template_b4187d9596864cd6ad79586c6be28fce } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_b4187d9596864cd6ad79586c6be28fce(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
