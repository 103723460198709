import { template as template_6dd3e2e584614f1a8ecb38123cd30b6e } from "@ember/template-compiler";
const SidebarSectionMessage = template_6dd3e2e584614f1a8ecb38123cd30b6e(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
